import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavbarAdmin from '../component/navbar_admin';

function AdministrationValeur() {
  const navigate = useNavigate();
  const [valeurs, setValeurs] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentValeur, setCurrentValeur] = useState(null);
  const [text, setText] = useState('');
  const [image, setImage] = useState();
  const token = localStorage.getItem('token');

  // Redirection si non authentifié
  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, [navigate, token]);

  useEffect(() => {
    const fetchValeurs = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/valeurs', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setValeurs(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des valeurs:", error);
      }
    };
    
    fetchValeurs();
  }, [token]);

  async function addValeurs() {
    const formData = new FormData();
    formData.append('text', text);
    formData.append('image', image);

    try {
      const response = await axios.post('https://dietetsportsante.fr/api/valeurs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function updateValeur(id) {
    const formData = new FormData();
    formData.append('text', text);
    if (image) {
      formData.append('image', image);
    }

    try {
      const response = await axios.put(`https://dietetsportsante.fr/api/valeurs/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function deleteUser(id) {
    try {
      const response = await axios.delete(`https://dietetsportsante.fr/api/valeurs/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleEdit = (valeur) => {
    setIsEditing(true);
    setCurrentValeur(valeur);
    setText(valeur.text);
    // On ne change pas l'image pour ne pas la perdre lors de l'édition
  };

  return (
    <div>
      <NavbarAdmin />
      {/* Container responsive : en colonne sur mobile et côte à côte sur md+ */}
      <div className="flex flex-col md:flex-row h-screen p-4 bg-gray-100">
        {/* Formulaire : affiché en premier sur mobile */}
        <div className="order-1 md:order-2 w-full md:w-1/2 md:pl-4 mb-4 md:mb-0">
          <h2 className="text-xl font-semibold mb-4">
            {isEditing ? "Modifier la valeur" : "Créer une nouvelle valeur"}
          </h2>
          <form className="space-y-4 bg-gray-500 p-6 rounded-lg shadow-lg">
            <div className="form-control">
              <label className="label">Image de présentation</label>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                className="file-input w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">Texte</label>
              <input
                type="text"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="input w-full"
                required
              />
            </div>
            <button
              type="button"
              onClick={isEditing ? () => updateValeur(currentValeur.id) : addValeurs}
              className="btn btn-primary w-full mt-4"
            >
              {isEditing ? "Mettre à jour la valeur" : "Créer la valeur"}
            </button>
          </form>
        </div>
        {/* Liste des valeurs : affichée en second sur mobile */}
        <div className="order-2 md:order-1 w-full md:w-1/2 md:pr-4">
          <h2 className="text-xl font-semibold mb-4">Mes valeurs</h2>
          <div className="overflow-y-auto h-full space-y-4">
            {valeurs.map((valeur) => (
              <div key={valeur.id} className="card card-side w-full bg-base-100 h-52 shadow-lg p-2 flex">
                {/* Image */}
                <figure className="w-1/3">
                  <img
                    src={"https://dietetsportsante.fr/api" + valeur.img}
                    alt={valeur.text}
                    className="w-full h-32 object-cover"
                  />
                </figure>
                {/* Contenu */}
                <div className="card-body w-2/3 p-4 flex flex-col justify-between">
                  <h3 className="card-text text-lg">{valeur.text}</h3>
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={() => handleEdit(valeur)}
                      className="btn btn-xs btn-outline btn-info"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => deleteUser(valeur.id)}
                      className="btn btn-xs btn-outline btn-error"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationValeur;
