import React from 'react';
import logo from '../img/logo.png';
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  // Fonction pour rediriger vers /ebook avec le bon filtre
  const handleFilterNavigation = (theme) => {
    navigate(`/ebook?theme=${encodeURIComponent(theme)}`);
  };

  return (
    <div className="navbar sticky top-0 z-50 bg-[#b49666] border-t border-white">
      {/* Logo et titre */}
      <div className="flex-1">
        <Link to="/">
          <div className="btn btn-ghost normal-case text-xl flex items-center">
            <img src={logo} alt="Logo" className="w-16 invert" />
            <span className="ml-2 text-white">Marie KNOBLOCH</span>
          </div>
        </Link>
      </div>

      {/* Menu desktop */}
      <div className="hidden md:block">
        <ul className="menu menu-horizontal text-xl text-white">
          <Link to="/quisuisje">
            <li className="hover:bg-[#1b1b1b]"><a>Qui suis-je</a></li>
          </Link>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Bouger")}><a>Bouger</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Manger")}><a>Manger</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Bien-être")}><a>Bien-être</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Budget")}><a>Budget</a></li>
        </ul>
      </div>

      {/* Menu mobile */}
      <div className="dropdown dropdown-end md:hidden">
        <label tabIndex="0" className="btn btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </label>
        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-[#b49666] rounded-box w-52">
          <Link to="/quisuisje">
            <li className="hover:bg-[#1b1b1b]"><a>Qui suis-je</a></li>
          </Link>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Bouger")}><a>Bouger</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Manger")}><a>Manger</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Bien-être")}><a>Bien-être</a></li>
          <li className="hover:bg-[#1b1b1b]" onClick={() => handleFilterNavigation("Budget")}><a>Budget</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
