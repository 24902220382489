import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavbarAdmin from '../component/navbar_admin';

function AdministrationWho() {
  const navigate = useNavigate();
  const [whos, setWhos] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentWho, setCurrentWho] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState();
  const token = localStorage.getItem('token');

  // Redirection si non authentifié
  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, [navigate, token]);

  useEffect(() => {
    const fetchWho = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/Whos', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setWhos(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des Whos:", error);
      }
    };
    
    fetchWho();
  }, [token]);

  async function addWhos() {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('image', image);

    try {
      await axios.post('https://dietetsportsante.fr/api/Whos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function updateWho(id) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    if (image) {
      formData.append('image', image); // On ajoute une nouvelle image si fournie
    }

    try {
      await axios.put(`https://dietetsportsante.fr/api/Whos/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload(); // Rafraîchir pour voir les changements
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function deleteUser(id) {
    try {
      await axios.delete(`https://dietetsportsante.fr/api/Whos/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleEdit = (who) => {
    setIsEditing(true);
    setCurrentWho(who);
    setTitle(who.title);
    setText(who.text);
    // On ne modifie pas l'image pour ne pas la perdre lors de l'édition
  };

  return (
    <div>
      <NavbarAdmin />

      {/* Container responsive : en colonne sur mobile et côte à côte sur md+ */}
      <div className="flex flex-col md:flex-row h-screen p-4 bg-gray-100">
        {/* Colonne du formulaire : affichée en premier sur mobile */}
        <div className="order-1 md:order-2 w-full md:w-1/2 md:pl-4 mb-4 md:mb-0">
          <h2 className="text-xl font-semibold mb-4">
            {isEditing ? "Modifier le Who" : "Créer un nouveau Who"}
          </h2>
          <form className="space-y-4 bg-gray-500 p-6 rounded-lg shadow-lg">
            <div className="form-control">
              <label className="label">Image de présentation</label>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                className="file-input w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">Titre</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">Texte</label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="textarea w-full"
                required
              />
            </div>
            <button
              type="button"
              onClick={isEditing ? () => updateWho(currentWho.id) : addWhos}
              className="btn btn-primary w-full mt-4"
            >
              {isEditing ? "Mettre à jour le Who" : "Créer le Who"}
            </button>
          </form>
        </div>

        {/* Colonne de la liste des Whos : affichée en second sur mobile */}
        <div className="order-2 md:order-1 w-full md:w-1/2 md:pr-4">
          <h2 className="text-xl font-semibold mb-4">Mes Who</h2>
          <div className="overflow-y-auto h-full space-y-4">
            {whos.map((who) => (
              <div key={who.id} className="card card-side w-full bg-base-100 h-52 shadow-lg p-2 flex">
                {/* Partie gauche : Image */}
                <figure className="w-1/3">
                  <img
                    src={"https://dietetsportsante.fr/api" + who.img}
                    alt={who.title}
                    className="w-full h-32 object-cover"
                  />
                </figure>
                {/* Partie droite : Contenu */}
                <div className="card-body w-2/3 p-4 flex flex-col justify-between">
                  <h3 className="card-title text-lg">{who.title}</h3>
                  <p className="text-sm mb-2">{who.text}</p>
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={() => handleEdit(who)}
                      className="btn btn-xs btn-outline btn-info"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => deleteUser(who.id)}
                      className="btn btn-xs btn-outline btn-error"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationWho;
