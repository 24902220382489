import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavbarAdmin from '../component/navbar_admin';

function AdministrationActu() {
  const navigate = useNavigate();
  const [Actus, setActus] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentActu, setCurrentActu] = useState(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [text2, setText2] = useState('');
  const [image, setImage] = useState();
  const [couleur, setCouleur] = useState();
  const token = localStorage.getItem('token');

  // Redirection si non authentifié
  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, [navigate, token]);

  useEffect(() => {
    const fetchActus = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/Actus', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setActus(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des Actus:", error);
      }
    };

    fetchActus();
  }, [token]);

  async function addActus() {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('text2', text2);
    formData.append('image', image);
    formData.append('couleur', couleur);

    try {
      await axios.post('https://dietetsportsante.fr/api/Actus', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function updateActu(id) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('text', text);
    formData.append('text2', text2);
    formData.append('couleur', couleur);
    if (image) {
      formData.append('image', image);
    }
    try {
      await axios.put(`https://dietetsportsante.fr/api/Actus/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function deleteUser(id) {
    try {
      await axios.delete('https://dietetsportsante.fr/api/Actus/' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleEdit = (Actu) => {
    setIsEditing(true);
    setCurrentActu(Actu);
    setTitle(Actu.title);
    setText(Actu.text);
    setText2(Actu.text2);
    setCouleur(Actu.couleur);
  };

  return (
    <div>
      <NavbarAdmin />
      {/* Container responsive : en colonne sur mobile, en ligne sur md+ */}
      <div className="flex flex-col md:flex-row h-screen p-4 bg-gray-100">
        {/* Colonne de droite (formulaire) - affichée en premier sur mobile */}
        <div className="order-1 md:order-2 w-full md:w-1/2 md:pl-4 mb-4 md:mb-0">
          <h2 className="text-xl font-semibold mb-4">
            {isEditing ? "Modifier l'Actualité" : "Créer une Actualité"}
          </h2>
          <form className="space-y-4 bg-gray-500 p-6 rounded-lg shadow-lg">
            <div className="form-control">
              <label className="label">Image de présentation</label>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                className="file-input w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">Titre</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">Résumé</label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="textarea w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">Texte</label>
              <textarea
                value={text2}
                onChange={(e) => setText2(e.target.value)}
                className="textarea w-full"
                required
              />
            </div>
            <div className="form-control">
              <label className="label">Couleur</label>
              <input
                type="color"
                value={couleur}
                onChange={(e) => setCouleur(e.target.value)}
                className="input w-full"
                required
              />
            </div>
            <button
              type="button"
              onClick={isEditing ? () => updateActu(currentActu.id) : addActus}
              className="btn btn-primary w-full mt-4"
            >
              {isEditing ? "Mettre à jour l'Actualité" : "Créer l'Actualité"}
            </button>
          </form>
        </div>

        {/* Colonne de gauche (liste des actualités) - affichée en second sur mobile */}
        <div className="order-2 md:order-1 w-full md:w-1/2 md:pr-4">
          <h2 className="text-xl font-semibold mb-4">Mes Actualités</h2>
          <div className="overflow-y-auto h-full space-y-4">
            {Actus.map((Actu) => (
              <div
                key={Actu.id}
                className="card w-full shadow-lg p-2 flex flex-col"
                style={{ backgroundColor: Actu.couleur }}
              >
                {/* Image */}
                <figure className="w-full">
                  <img
                    src={"https://dietetsportsante.fr/api" + Actu.img}
                    alt={Actu.title}
                    className="w-full h-32 object-cover"
                  />
                </figure>
                {/* Contenu */}
                <div className="card-body p-4 flex flex-col">
                  <h3 className="card-title text-lg">{Actu.title}</h3>
                  <p className="text-sm mb-2" style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                    {Actu.text}
                  </p>
                  <p className="text-sm mb-2" style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                    {Actu.text2}
                  </p>
                  <div className="flex justify-end space-x-2 mt-2">
                    <button
                      onClick={() => handleEdit(Actu)}
                      className="btn btn-xs btn-outline btn-info"
                    >
                      Modifier
                    </button>
                    <button
                      onClick={() => deleteUser(Actu.id)}
                      className="btn btn-xs btn-outline btn-error"
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationActu;
