import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavbarAdmin from '../component/navbar_admin';

function AdministrationLogo() {
  const navigate = useNavigate();
  const [logos, setLogos] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(null);
  const [link, setLink] = useState('');
  const [image, setImage] = useState();
  const token = localStorage.getItem('token');

  // Redirection si non authentifié
  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, [navigate, token]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/logos', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setLogos(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des logos:", error);
      }
    };

    fetchLogos();
  }, [token]);

  async function addLogos() {
    const formData = new FormData();
    formData.append('link', link);
    formData.append('image', image);

    try {
      const response = await axios.post('https://dietetsportsante.fr/api/logos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function updateLogo(id) {
    const formData = new FormData();
    formData.append('link', link);
    if (image) {
      formData.append('image', image); // Si une nouvelle image est fournie, ajoutez-la
    }

    try {
      const response = await axios.put(`https://dietetsportsante.fr/api/logos/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload(); // Rafraîchir pour voir les changements
    } catch (error) {
      console.log('Error:', error);
    }
  }

  async function deleteUser(id) {
    try {
      const response = await axios.delete('https://dietetsportsante.fr/api/logos/' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
    }
  }

  const handleEdit = (logo) => {
    setIsEditing(true);
    setCurrentLogo(logo);
    setLink(logo.link);
    // On ne modifie pas l'image ici afin de ne pas la perdre lors de l'édition
  };

  return (
    <div>
      <NavbarAdmin />
      {/* Container responsive : en colonne sur mobile (formulaire en haut) et en ligne sur md+ */}
      <div className="flex flex-col md:flex-row h-screen p-4 bg-gray-100">
        {/* Colonne du formulaire : affichée en premier sur mobile */}
        <div className="order-1 md:order-2 w-full md:w-1/2 md:pl-4 mb-4 md:mb-0">
          <h2 className="text-xl font-semibold mb-4">
            {isEditing ? "Modifier le logo" : "Créer un nouveau logo"}
          </h2>
          <form className="space-y-4 bg-gray-500 p-6 rounded-lg shadow-lg">
            <div className="form-control">
              <label className="label">Image de présentation</label>
              <input
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                className="file-input w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">Lien</label>
              <input
                type="text"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                className="input w-full"
                required
              />
            </div>
            <button
              type="button"
              onClick={isEditing ? () => updateLogo(currentLogo.id) : addLogos}
              className="btn btn-primary w-full mt-4"
            >
              {isEditing ? "Mettre à jour le logo" : "Créer le logo"}
            </button>
          </form>
        </div>

        {/* Colonne de la liste des logos : affichée en second sur mobile */}
        <div className="order-2 md:order-1 w-full md:w-1/2 md:pr-4">
          <h2 className="text-xl font-semibold mb-4">Mes logos</h2>
          <div className="overflow-y-auto h-full space-y-4">
            {logos.map((logo) => (
              <div key={logo.id} className="card card-side w-full bg-base-100 h-52 shadow-lg p-2 flex">
                {/* Partie gauche : image */}
                <figure className="w-1/3">
                  <img
                    src={"https://dietetsportsante.fr/api" + logo.img}
                    alt={logo.title}
                    className="w-full h-32 object-cover"
                  />
                </figure>
                {/* Partie droite : contenu */}
                <div className="card-body w-2/3 p-4 flex flex-col justify-between">
                  <div className="flex items-center justify-between">
                    <a href={logo.link} className="text-blue-500 hover:underline text-sm">Lien</a>
                    <div className="space-x-2">
                      <button
                        onClick={() => handleEdit(logo)}
                        className="btn btn-xs btn-outline btn-info"
                      >
                        Modifier
                      </button>
                      <button
                        onClick={() => deleteUser(logo.id)}
                        className="btn btn-xs btn-outline btn-error"
                      >
                        Supprimer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdministrationLogo;
