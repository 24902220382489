import React, { useState, useEffect } from 'react';
import axios from "axios";
import Navbar from '../component/navbar';
import bouger from '../img/bouger.png';
import manger from '../img/manger.png';
import bienetre from '../img/bienetre.png';
import budget from '../img/budget.png';
import logos from '../img/logo.png';
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [whoData, setWhoData] = useState([]);
  const [logo, setLogo] = useState([]);
  const [suiviData, setSuiviData] = useState([]);
  const [valeurData, setValeurData] = useState([]);
  const [ebooks, setEbooks] = useState([]);
  const [actualites, setActualites] = useState([]);
  const [selectedActu, setSelectedActu] = useState(null);
  const [formData, setFormData] = useState({
    prenom: "",
    nom: "",
    mail: "",
    tel: "",
    desc: "",
  });
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleRedirect = (theme) => {
    navigate(`/ebook?theme=${encodeURIComponent(theme)}`);
  };


  

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://dietetsportsante.fr/api/Contacts", formData);
      setMessage("Votre message a bien été envoyé ! ✅");
      setFormData({ prenom: "", nom: "", mail: "", tel: "", desc: "" });
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire:", error);
      setMessage("Erreur lors de l'envoi du message. ❌");
    }
  };



  useEffect(() => {
    const fetchData = async () => {
      try {
        const whoResponse = await axios.get('https://dietetsportsante.fr/api/Whos');
        setWhoData(whoResponse.data);

        const suiviResponse = await axios.get('https://dietetsportsante.fr/api/Suivis');
        setSuiviData(suiviResponse.data);

        const valeurResponse = await axios.get('https://dietetsportsante.fr/api/Valeurs');
        setValeurData(valeurResponse.data);

        const logoResponse = await axios.get('https://dietetsportsante.fr/api/Logos');
        setLogo(logoResponse.data);

        const actualitesResponse = await axios.get('https://dietetsportsante.fr/api/Actus');
        setActualites(actualitesResponse.data);

        // Récupération des ebooks et tri par date décroissante pour ne garder que les 4 plus récents
        const ebooksResponse = await axios.get('https://dietetsportsante.fr/api/Ebooks');
        const sortedEbooks = ebooksResponse.data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Tri des ebooks par date
          .slice(0, 4); // On prend uniquement les 4 plus récents
        setEbooks(sortedEbooks);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <div className="flex flex-col md:flex-row h-auto md:h-screen w-full bg-black overflow-hidden">
      {/* Partie gauche : carrousel */}
      <div className="flex flex-col md:flex-row w-full md:w-3/5 h-auto md:h-screen">
        {[
          { src: bouger, title: "Bouger" },
          { src: manger, title: "Manger" },
          { src: bienetre, title: "Bien-être" },
          { src: budget, title: "Budget" }
        ].map((item, index) => (
          <div
            key={index}
            className={`relative flex flex-col justify-end transition-all duration-500 ease-in-out h-full ${hoveredIndex === index ? 'md:w-2/5' : 'md:w-1/4'} w-full cursor-pointer`}
            style={{ flexBasis: hoveredIndex === index ? '50%' : '25%' }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleRedirect(item.title)} // Redirige vers /ebook avec le thème sélectionné
          >
            <img
              src={item.src}
              alt={item.title}
              className={`absolute top-0 left-0 w-full h-full object-cover transition-transform duration-500 ease-in-out ${hoveredIndex === index ? 'scale-110' : 'scale-100'}`}
            />
            <div className="absolute bottom-0 w-full text-white text-xl bg-black bg-opacity-50 py-2 transition-opacity duration-500 ease-in-out text-center">
              {item.title}
            </div>
          </div>
        ))}
      </div>
        {/* Partie droite : logo et texte */}
        <div className="flex w-full md:w-2/5 items-center justify-center flex-col p-4 mt-4 md:mt-0">
          <img src={logos} alt="Logo" className="w-1/3 h-auto object-contain mb-4 invert" />
          <h1 className="text-white text-3xl">Marie KNOBLOCH</h1>
          <p className="text-white text-xl">Coach sportif et diététicienne</p>
        </div>
      </div>

      <Navbar />

      {/* Section "Qui êtes-vous ?" */}
      <div className="bg-[#1b1b1b] pt-20 w-full min-h-screen">
        <h1 className="text-4xl text-white text-center">Qui êtes-vous ?</h1>
        <div className="flex flex-wrap items-center justify-center gap-8 p-4">
          {whoData.map((item, index) => (
            <div key={index} className="card rounded-none w-80 h-[450px] bg-base-100 shadow-xl mb-8">
              <figure>
                <img className="h-40 pt-5" src={"https://dietetsportsante.fr/api" + item.img} alt={item.title} />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{item.title}</h2>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section "Suivi personnalisé à distance" */}
      <div className="bg-[#eceee9] pt-20 w-full h-auto md:h-[500px] flex flex-col justify-center">
        <h1 className="text-4xl text-black text-center">Suivi personnalisé à distance</h1>
        <div className="flex flex-wrap items-center justify-center gap-8 p-4">
          {suiviData.map((suivi, index) => (
            <div key={index} className="card rounded-none w-80 md:w-96 bg-[#b49666] shadow-xl mb-8">
              <div className="card-body items-center text-center">
                <h2 className="card-title">{suivi.title}</h2>
                <div className="card-actions justify-end pt-5">
                  <a target="_blank" rel="noopener noreferrer" href={suivi.link}>
                    <button className="btn btn-primary w-full rounded-none">
                      Je prends rendez-vous
                    </button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Section "Mes Ebooks" */}
      <div className="bg-[#1b1b1b] pt-20 w-full h-auto md:h-[700px] flex flex-col justify-center">
        <h1 className="text-4xl text-white text-center">Mes Ebooks</h1>
        <div className="flex flex-wrap items-center justify-center gap-8 p-4">
          {ebooks.map((ebook, index) => (
            <div key={index} className="card rounded-none w-80 md:w-96 bg-base-100 shadow-xl mb-8">
              <figure>
                <img className="h-64 object-cover" src={"https://dietetsportsante.fr/api" + ebook.img} alt={ebook.title} />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{ebook.title}</h2>
                <p>{ebook.description}</p>
                <div className="card-actions justify-end">
                  <a href={ebook.link} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">Acheter</button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
     {/* Section Actualités avec modal */}
     <div className="pt-20 w-full min-h-screen">
        <h1 className="text-4xl text-black text-center">Actualités</h1>
        <div className="flex flex-wrap items-center justify-center gap-8 p-4 mx-4 md:mx-52 mt-10">
          {actualites.map((actu, index) => (
            <div
              key={index}
              className="card w-80 md:w-96 h-auto md:h-[500px] shadow-xl rounded-none mb-8"
              style={{ backgroundColor: actu.couleur }}
            >
              <figure>
                <img className="h-64 w-full object-cover" src={"https://dietetsportsante.fr/api" + actu.img} alt={actu.title} />
              </figure>
              <div className="card-body">
                <h2 className="card-title">{actu.title}</h2>
                <p>{actu.text}</p>
                <div className="card-actions justify-end">
                  <button className="btn btn-primary" onClick={() => setSelectedActu(actu)}>
                    Lire la suite
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* MODAL DaisyUI */}
      {selectedActu && (
        <div className="modal modal-open">
          <div className="modal-box">
            <h2 className="text-2xl font-bold mb-4">{selectedActu.title}</h2>
            <p>{selectedActu.text2}</p>
            <div className="modal-action">
              <button className="btn btn-secondary" onClick={() => setSelectedActu(null)}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Section "Mes valeurs" */}
      <div className="bg-[#b49666] pt-20 w-full h-auto md:h-[500px]">
        <h1 className="text-4xl text-black text-center">Mes valeurs</h1>
        <div className="flex flex-wrap items-center justify-center gap-8 p-4">
          {valeurData.map((valeur, index) => (
            <div key={index} className="flex flex-col items-center m-4">
              <div className="rounded-full w-40 h-40 md:w-52 md:h-52 bg-white overflow-hidden flex justify-center items-center">
                <img src={"https://dietetsportsante.fr/api" + valeur.img} className="w-32 h-32 md:w-44 md:h-44 object-cover" alt={valeur.title} />
              </div>
              <p className="mt-4 text-center font-semibold px-2">{valeur.text}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Section Contact */}
      <div className="bg-gray-100 py-8 px-6">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Contactez-moi</h2>
        <form className="max-w-4xl mx-auto space-y-4 bg-white rounded-md shadow-md p-6" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full lg:w-1/2 px-3 mb-4 lg:mb-0">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Prénom
              </label>
              <input
                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                placeholder="Jean"
                required
              />
            </div>
            <div className="w-full lg:w-1/2 px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Nom
              </label>
              <input
                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                placeholder="Dupont"
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Email
              </label>
              <input
                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="email"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                placeholder="email@example.com"
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Numéro de téléphone
              </label>
              <input
                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                placeholder="+33 6 12 34 56 78"
                required
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Message
              </label>
              <textarea
                className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white h-48 resize-none"
                name="desc"
                value={formData.desc}
                onChange={handleChange}
                placeholder="Votre message ici..."
                required
              ></textarea>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mt-2">
            <div className="w-full px-3">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                type="submit"
              >
                Envoyer
              </button>
            </div>
          </div>
        </form>
        {message && <p className="text-center mt-4 text-lg font-semibold">{message}</p>}
      </div>

      {/* Footer */}
      <footer className="footer footer-center p-10 bg-[#b49666] text-base-content rounded">
        <nav className="grid grid-cols-2 md:grid-cols-5 gap-4">
          <a className="link link-hover">Index</a>
          <a className="link link-hover">Bouger</a>
          <a className="link link-hover">Manger</a>
          <a className="link link-hover">Bien-être</a>
          <a className="link link-hover">Budget</a>
        </nav>
        <nav className="mt-4">
          <div className="grid grid-flow-col gap-4">
            {logo.map((logo) => (
              <a key={logo.id} href={logo.link} target="_blank" rel="noopener noreferrer">
                <img
                  src={"https://dietetsportsante.fr/api" + logo.img}
                  alt={logo.name}
                  className="w-6 h-6 hover:opacity-75 transition duration-200"
                />
              </a>
            ))}
          </div>
        </nav>
        <aside className="mt-4">
          <p>Copyright © 2025 - All rights reserved by Marie KNOBLOCH</p>
        </aside>
      </footer>
    </div>
  );
};

export default Index;
