import React from 'react';
import logo from '../img/logo.png';
import { useNavigate, Link } from "react-router-dom";

const NavbarAdmin = () => {
  const navigate = useNavigate();

  const disconnect = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    navigate("/");
  };

  return (
    <div className="navbar sticky top-0 z-50 bg-[#b49666] border-t border-white">
      {/* Logo et titre */}
      <div className="flex-1">
        <a className="btn btn-ghost normal-case text-xl flex items-center">
          <img src={logo} alt="Logo" className="w-16 invert" />
          <span className="ml-2 text-white">Marie KNOBLOCH</span>
        </a>
      </div>

      {/* Menu Desktop */}
      <div className="hidden md:block">
        <ul className="menu menu-horizontal text-xl text-white">
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration">Ebooks</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_actualite">Actualités</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_qui">Qui êtes-vous</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_suivi">Suivi personnalisé</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_valeur">Mes valeurs</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_logo">Logos</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_quisuisje">Qui suis-je</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_contact">Contact</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <button
              onClick={disconnect}
              className="btn text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded-xl ml-2"
            >
              Déconnexion
            </button>
          </li>
        </ul>
      </div>

      {/* Menu Mobile */}
      <div className="dropdown dropdown-end md:hidden">
        <label tabIndex="0" className="btn btn-ghost">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </label>
        <ul
          tabIndex="0"
          className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-[#b49666] rounded-box w-52"
        >
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration">Ebooks</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_actualite">Actualités</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_qui">Qui êtes-vous</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_suivi">Suivi personnalisé</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_valeur">Mes valeurs</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_logo">Logos</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_quisuisje">Qui suis-je</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <Link to="/administration_contact">Contact</Link>
          </li>
          <li className="hover:bg-[#1b1b1b]">
            <button
              onClick={disconnect}
              className="btn text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded-xl mt-2"
            >
              Déconnexion
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarAdmin;
