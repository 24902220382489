import React, { useState, useEffect } from 'react';
import axios from "axios";
import Navbar from '../component/navbar';
import { useSearchParams, useNavigate } from "react-router-dom";

const EbookPage = () => {
  const [ebooks, setEbooks] = useState([]);
  const [filteredEbooks, setFilteredEbooks] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Récupère le paramètre de thème depuis l'URL ou affiche "Tous" par défaut
  const selectedTheme = searchParams.get("theme") || "Tous";

  useEffect(() => {
    const fetchEbooks = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/Ebooks');
        setEbooks(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des ebooks:", error);
      }
    };
    fetchEbooks();
  }, []);

  // Filtrage automatique des ebooks lorsque `selectedTheme` change
  useEffect(() => {
    if (selectedTheme === "Tous") {
      setFilteredEbooks(ebooks);
    } else {
      setFilteredEbooks(ebooks.filter(ebook => ebook.theme === selectedTheme));
    }
  }, [selectedTheme, ebooks]);

  // Fonction pour changer le filtre et mettre à jour l'URL
  const handleFilter = (theme) => {
    navigate(`/ebook?theme=${encodeURIComponent(theme)}`);
  };

  return (
    <div className="bg-[#1b1b1b] min-h-screen text-white">
      <Navbar />

      {/* Titre */}
      <div className="text-center py-16">
        <h1 className="text-4xl font-bold text-[#b49666]">📚 Mes Ebooks</h1>
      </div>

      {/* Filtres basés sur le thème */}
      <div className="flex justify-center gap-4 mb-10 flex-wrap">
        {["Tous", "Manger", "Bien-être", "Bouger", "Budget"].map(theme => (
          <button
            key={theme}
            className={`px-6 py-2 rounded-md font-semibold transition ${
              selectedTheme === theme
                ? "bg-[#b49666] text-white"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }`}
            onClick={() => handleFilter(theme)}
          >
            {theme}
          </button>
        ))}
      </div>

      {/* Liste des Ebooks */}
      <div className="flex flex-wrap items-center justify-center gap-8 px-4">
        {filteredEbooks.length > 0 ? (
          filteredEbooks.map((ebook, index) => (
            <div key={index} className="card rounded-none h-[500px] w-80 md:w-96 bg-base-100 shadow-xl mb-8">
              <figure>
                <img className="h-64 object-cover w-full" src={`https://dietetsportsante.fr/api${ebook.img}`} alt={ebook.title} />
              </figure>
              <div className="card-body">
                <h2 className="card-title text-black">{ebook.title}</h2>
                <p className='text-black'>{ebook.text}</p>
                <div className="card-actions justify-end">
                  <a href={ebook.link} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-primary">Acheter</button>
                  </a>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg text-gray-400 text-center w-full">Aucun ebook trouvé dans cette catégorie.</p>
        )}
      </div>
    </div>
  );
};

export default EbookPage;
