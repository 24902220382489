import React, { useEffect, useState } from "react";
import axios from "axios";
import NavbarAdmin from '../component/navbar_admin';
import { useNavigate } from "react-router-dom";

const AdministrationContact = () => {
  const [contacts, setContacts] = useState([]);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  // Redirection si non authentifié
  useEffect(() => {
    if (!token) {
      navigate('/admin');
    }
  }, [navigate, token]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get("https://dietetsportsante.fr/api/contacts", {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setContacts(response.data);
    } catch (error) {
      console.error("Erreur lors du chargement des contacts:", error);
    }
  };

  const deleteContact = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce contact ?")) {
      try {
        await axios.delete(`https://dietetsportsante.fr/api/contacts/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setContacts(contacts.filter(contact => contact.id !== id)); // Mise à jour de la liste
      } catch (error) {
        console.error("Erreur lors de la suppression:", error);
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <NavbarAdmin />

      <div className="container mx-auto px-4">
        <h1 className="text-3xl font-bold pt-20 text-center mb-6">📋 Liste des Contacts</h1>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="py-3 px-6 text-left">ID</th>
                <th className="py-3 px-6 text-left">Prénom</th>
                <th className="py-3 px-6 text-left">Nom</th>
                <th className="py-3 px-6 text-left">Email</th>
                <th className="py-3 px-6 text-left">Téléphone</th>
                <th className="py-3 px-6 text-left">Message</th>
                <th className="py-3 px-6 text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-xs md:text-sm">
              {contacts.length > 0 ? (
                contacts.map((contact) => (
                  <tr key={contact.id} className="border-b hover:bg-gray-100">
                    <td className="py-3 px-6">{contact.id}</td>
                    <td className="py-3 px-6">{contact.prenom}</td>
                    <td className="py-3 px-6">{contact.nom}</td>
                    <td className="py-3 px-6">{contact.mail}</td>
                    <td className="py-3 px-6">{contact.tel}</td>
                    <td className="py-3 px-6">{contact.desc}</td>
                    <td className="py-3 px-6 text-center">
                      <button
                        onClick={() => deleteContact(contact.id)}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-4">Aucun contact trouvé.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdministrationContact;
