import React, { useState } from 'react';
import logo from '../img/logo.png';
import marillette from '../img/marillette.jpg';
import axios from "axios";
import {useNavigate} from "react-router-dom" 


const Admin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [VisibleAlertOK, setVisibleAlertOK] = React.useState(false)
  const [VisibleAlertNOK, setVisibleAlertNOK] = React.useState(false)
  const n = useNavigate()


  const signin = async () => {
    try {
      const response = await axios.post('https://dietetsportsante.fr/api/login', {
        username: username,
        mdp: password
      }, {
        credentials: "same-origin",
        headers: {
          'Content-Type': 'application/json', // Définir le type de contenu
          "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      });
  
      console.log(response.data.token);
      setVisibleAlertOK(true);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', response.data.user.username)
      n('/administration');
    } catch (error) {
      console.log('error ', error);
      setVisibleAlertNOK(true);
    }
  };
  


  return (
    <div className="flex items-center justify-center min-h-screen  bg-[#1b1b1b] ">
      <div className="p-8 bg-[#b49666] opacity-80 rounded-2xl shadow-xl">
        <form className="flex flex-col space-y-4">
          <div className="flex justify-center">
            <img src={logo} alt="logo" className="mb-6 h-24 rounded-lg" />
          </div>
          <div>
            <label htmlFor="username" className="text-sm font-medium">Nom utilisateur</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input input-bordered w-full mt-1"
            />
          </div>
          <div>
            <label htmlFor="password" className="text-sm font-medium">Mot de passe</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input input-bordered w-full mt-1"
            />
          </div>
          <button onClick={signin} type="button" className="btn btn-primary bg-[#BAC82F] border-[#BAC82F] text-black">
            Connexion
          </button>
        </form>
        { VisibleAlertOK ? <div className="alert alert-success shadow-lg"><div><svg xmlns="www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                 <span>Bienvenue ! Vous êtes connectés</span></div></div> : null }
              { VisibleAlertNOK ? <div className="alert alert-error shadow-lg"><div><svg xmlns="www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                  <span>Nom utilisateur ou mot de passe incorrect ! </span></div></div> : null }
      </div>
    </div>
  );
};

export default Admin;