import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Index from "./pages/index"
import Admin from "./pages/admin"
import Administration from "./pages/administration";
import AdministrationActu from "./pages/administration_actu";
import AdministrationWho from "./pages/administration_who";
import AdministrationSuivi from "./pages/administration_suivi";
import AdministrationValeur from "./pages/administration_valeur";
import AdministrationLogo from "./pages/administration_logo";
import AdministrationQuisuisje from "./pages/administration_quisuisje";
import AdministrationContact from "./pages/administration_contact";
import QuiSuisJe from "./pages/quisuije";
import EbookPage from "./pages/ebook";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/administration" element={<Administration />} />
        <Route path="/administration_actualite" element={<AdministrationActu />} />
        <Route path="/administration_qui" element={<AdministrationWho />} />
        <Route path="/administration_suivi" element={<AdministrationSuivi/>} />
        <Route path="/administration_valeur" element={<AdministrationValeur/>} />
        <Route path="/administration_logo" element={<AdministrationLogo/>} />
        <Route path="/administration_quisuisje" element={<AdministrationQuisuisje/>} />
        <Route path="/administration_contact" element={<AdministrationContact/>} />
        <Route path="/quisuisje" element={<QuiSuisJe/>} />
        <Route path="/ebook" element={<EbookPage/>} />
        </Routes>
    </Router>
  );
}

export default App;