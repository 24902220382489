import React, { useState, useEffect } from 'react';
import axios from "axios";
import Navbar from '../component/navbar';

const QuiSuisJe = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchQuis = async () => {
      try {
        const response = await axios.get('https://dietetsportsante.fr/api/Quisuisjes');
        setData(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };
    
    fetchQuis();
  }, []);

  return (
    <div className="bg-[#1b1b1b] min-h-screen text-white">
      <Navbar />
      
      <div className="flex flex-col items-center px-6 md:px-20 py-16 md:py-24">
        {data.map((item, index) => (
          <div 
            key={index} 
            className={`flex flex-col-reverse md:flex-row items-center justify-center w-full max-w-6xl mb-12 ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
          >
            {/* Image avec encadrement */}
            <div className="w-full md:w-1/2 flex justify-center mb-8 md:mb-0">
              <div className="border-8 border-[#b49666] shadow-lg rounded-lg p-2">
                <img
                  src={`https://dietetsportsante.fr/api${item.img}`}
                  alt={item.title}
                  className="w-full h-[500px] object-cover rounded-lg"
                />
              </div>
            </div>

            {/* Encadré texte de présentation */}
            <div className="w-full md:w-1/2 p-8 bg-[#b49666] text-white rounded-lg shadow-lg">
              <h1 className="text-4xl font-bold mb-4">{item.title}</h1>
              <p className="text-lg leading-relaxed">{item.text}</p>
              <div className="mt-6">
                <a href="/contact">
                  <button className="bg-white text-[#b49666] hover:bg-gray-200 font-bold py-3 px-6 rounded-md transition">
                    Me contacter
                  </button>
                </a>
              </div>
            </div>
          </div>
        ))}

        {/* Si aucune donnée n'est disponible */}
        {data.length === 0 && (
          <p className="text-lg text-gray-400 text-center">Aucune information disponible pour le moment.</p>
        )}
      </div>
    </div>
  );
};

export default QuiSuisJe;
